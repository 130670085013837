




































































import { Component, Vue } from 'vue-property-decorator'
import { vxm } from '../store/store.vuex'
import { USER_LOGIN, USER_LOGOUT } from '../store/actions'

@Component
export default class Login extends Vue {
  login() {
    this.$store.dispatch('user/' + USER_LOGIN)
  }

  logout() {
    this.$store.dispatch('user/' + USER_LOGOUT)
  }

  goToHomepage() {
    this.$router.push('/photos-manager/homepage')
    this.$store.dispatch('app/setSelectedPhotoManagerComponent', 'homepage')
  }

  get user() {
    return vxm.user.getUser
  }

  get hasEditPermission() {
    return vxm.user.hasEditPermission
  }
}
